<template>
    <v-container fluid fill-height v-bind:style="{ 'background-image': 'url(' + bgImg + ')' }" class="bg-container">
        <v-container>
            <v-row >
                <v-col cols="12" sm="1"></v-col>
                <v-col cols="12" sm="3">
                    <h1 class="primary-hd large-text">SUPER<br/>SCI</h1>
                </v-col>
                <v-col cols="12" sm="4" align="center" class="justify-center">
                    <div v-if='User && User.role == "student"' class="profile-img-container">
                        <v-img :src="twoB" contain class="profile-img"></v-img>
                    </div>
                    <div v-if='User && User.role == "teacher"' class="profile-img-container">
                            <ImageUploader v-model="avatar">
                                <div slot="activator">
                                    <v-avatar v-ripple v-if="!avatar" class="grey lighten-3 mb-3 avatar-section">
                                        <v-icon x-large class="custom-icon">mdi-upload</v-icon>
                                    </v-avatar>
                                    <v-avatar v-ripple v-else class="mb-3 avatar-section">
                                        <img :src="tempImgURL" alt="avatar">  
                                    </v-avatar>
                                </div>
                            </ImageUploader>
                    </div>
                </v-col>
                <v-col cols="12" sm="4"></v-col>
            </v-row>    
            <v-row  v-if='User && User.role == "teacher"'>
                <v-col cols="12" sm="3"></v-col>
                <v-col cols="12" sm="6" align="center" class="justify-center">
                    <h1 class="primary-hd large-text">School Name</h1>
                </v-col>
                <v-col cols="12" sm="3"></v-col>
            </v-row>       
            <v-row v-if="toggleView">
                <v-col cols="12" sm="4"></v-col>
                <v-col cols="12" sm="4">
                    <div class="profile-info text-center">
                        <h1>{{User.name}}</h1>
                        <h1 v-if='User && User.role == "teacher"'>ID</h1>
                        <h1>{{User.email}}</h1>
                    </div>
                </v-col>
                <v-col cols="12" sm="4"></v-col>
            
            </v-row>
            <v-row v-if="toggleView">
                <v-col cols="12" sm="4"></v-col>
                <v-col cols="12" sm="4" align="center" class="justify-center">
                    <div class="text-center">
                        <h1 class="primary-hd" v-if='User && User.role == "teacher"'>Grade: 5</h1>
                    </div>                
                </v-col>
                <v-col cols="12" sm="4"></v-col>
            </v-row>
            <v-row v-if='User && User.role == "student" || !toggleView'>
                <ResetPassword></ResetPassword>
            </v-row>
            <span v-if="toggleView">
                <v-row  v-if='User && User.role == "teacher"'>
                <v-col cols="12" sm="4"></v-col>
                <v-col cols="12" sm="4" >
                    <div class="text-center">
                        <v-btn class="profile-btn mb-5"
                            type="button" block  
                            @click="toggleView = false"  
                        >
                            Reset Password
                        </v-btn> 
                        <v-btn class="profile-btn"
                            type="button" block    
                        >
                            Add New User
                        </v-btn> 
                    </div>
                </v-col>
                <v-col cols="12" sm="4"></v-col>        
                </v-row>
            </span>
            <v-row align="end" class="footer">
                <v-col cols="12" sm="2">
                </v-col>
                <v-col cols="12" sm="2">
                   <BackBtn @close="goBack()"></BackBtn>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import background from '@/assets/white-science-icon-background.jpg';
import twoB from '@/assets/2B-with-Beaker.png';

import ResetPassword from '@/components/ResetPassword';
import BackBtn from '@/components/BackBtn';
import ImageUploader from '@/components/ImageUploader'

export default {
    name: 'Profile',
    components:{
        ResetPassword,
        BackBtn,
        ImageUploader

    },
    data() {
        return {
            bgImg: background,
            twoB: twoB,
            toggleView: true,
            avatar: false,
            tempImgURL: "",
        };
    },    
    computed:{
        User(){
            return this.$store.state.user;
        }
    },
    created(){
        this.tempImgURL = this.$store.state.profileImage;
        if(this.tempImgURL!=null){
            this.avatar = true;
        }
    },
    watch: {
        avatar(){
            this.tempImgURL = this.$store.state.profileImage;
            this.avatar = true;
        }
    },
    methods: {
        goBack(){
            // if(this.toggleView){
            //     this.$router.go(-1);
            // }else{
            //     this.toggleView = true;
            // }
            if(!this.toggleView && this.User.role == "teacher" ){
                this.toggleView = true;
            }else{
                this.$router.go(-1);
            }
        },
        // to handle image
        uploadImage() {
        }
    }
};
</script>
<style scoped>

.primary-hd{
    font-family: 'Lomo', sans-serif;
    letter-spacing: 5px;
}

.profile-img-container{
    border: 2px solid black;
    background-color: #9ee8f88c;
    box-sizing: border-box; 
    padding: 0.5rem;
    width: 11rem;
    height: 11rem;
}

.profile-img{
    width: 9rem;
    height: 9rem;
}

.avatar-section{
    width: 9.5rem!important;
    height: 9.5rem!important;
    border-radius: 0%;
}

.profile-info{
    border: 2px solid black;
    background-color: #01AD87;
    padding: 0.5rem;
    font-size: 0.5rem;
}

.reset-container{
    border: 2px solid black;
    font-size: 0.5rem;
}

.reset-container h1{
    background: #9EE7F8;
    border-bottom: 2px solid black;
}

.profile-btn{
    border: 2px solid black;
    background-color: #9EE7F8 !important;
}

.custom-icon{
    font-size: 7rem !important;
    padding: 0;
    margin: 0;
}
</style>