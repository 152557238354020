<template>
    <v-container>
        <v-row >
            <v-col cols="12" sm="4"></v-col>
            <v-col cols="12" sm="4" >
                <div class="reset-container text-center" >
                    <h1 class="py-2">Reset Password</h1>
                    <v-form>
                        <v-text-field           
                            name="password"
                            label="New Password"
                            v-model="password"
                            outlined solo text hide-details
                            class="rounded-0"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                        >
                        </v-text-field>
                        <v-text-field          
                            name="confirmPassword"
                            label="Re-enter New Password"
                            v-model="confirmPassword"
                            outlined solo text hide-details
                            class="rounded-0"
                            :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword1 ? 'text' : 'password'"
                            @click:append="showPassword1 = !showPassword1"
                        >
                        </v-text-field>
                    </v-form>
                </div>
            </v-col>
            <v-col cols="12" sm="4"></v-col>        
        </v-row>
    </v-container>
</template>

<script>

export default {
    name: 'ResetPassword',
    data() {
        return {
            password: "",
            confirmPassword: "",
            showPassword: false,
            showPassword1: false
        };
    },
};
</script>
<style scoped>


.reset-container{
    border: 2px solid black;
    font-size: 0.5rem;
}

.reset-container h1{
    background: #9EE7F8;
    border-bottom: 2px solid black;
}


</style>